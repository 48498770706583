import { graphql } from 'gatsby'
import React,{useState, useEffect, useRef} from 'react'
import axios from 'axios';
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import ImageRenderer from '../components/ImageRenderer'
import {Link, Trans, useTranslation} from 'gatsby-plugin-react-i18next'
import Layout from '../components/layout'
import { useMediaQuery } from 'react-responsive'
import useWindowSize from '../hooks/useGatsbyWindowSize'
import {gsap} from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { CustomEase } from "gsap/CustomEase";

gsap.registerPlugin(ScrollToPlugin);
gsap.registerPlugin(CustomEase);
gsap.config({autoKillThreshold: 1});

const ProjectsPage = (props) => {

 const { t } = useTranslation();
 const [modal, setModal] = useState('off');
 const [navStatus, setNavStatus] = useState(true);
 const [modalData, setModalData] = useState({});
  const [active, setActive] = useState('c');
 const [isLoaded, setIsLoaded] = useState(false);
 const [isMobile, setIsMobile] = useState(false);
 const [listContent, setlistContent] = useState('');
 const mobile = useMediaQuery({ query: `(max-width: 767px)` });
 const {width,height} = useWindowSize();
 var _y = 100;

 const scrollElem = useRef();

 //console.log( 'mobile :' + isMobile );

  useEffect(() => {



    setIsMobile(mobile);
    setIsLoaded(true);

    loadProjects('c');

    if(mobile){
      _y =  100;
       gsap.to(document.body,1,{delay: 0, ease: 'Quad.easeOut', scrollTo:{y:  _y, autoKill: false }});


    }


}, [mobile,height]);

function loadProjects(_param){

  var source_url = 'https://stage5345.oscarjaenada.com';
  var _json = source_url + '/api/v1/projects/' + _param ;
  //  var _json = '/static/oj.json'

  axios.get(_json)
.then(function (response) {
  //console.log(response.data);

  var content = response.data.data.map((item,i) => {
    //console.log(item.image.src);
    var _date = item.date.split("-")[0];
    return(
    <div className="project" key={'p-'+i}>
    <a
    data-image={source_url + item.image.src}
    data-title={item.title}
    data-desc={item.description}
    data-date={_date}

    href={"#" + item.slug}
    onClick={(e)=> prepareModal(e)}>
    <ImageRenderer
       key={item.id}
       url={source_url + item.image.src}
       width={400 }
       height={585}
       alt_tag={'Oscar Jaenada ' +item.title}
       />
       <div className="project_desc">
       <span className="proj_date">{_date}</span>
       </div>
       </a>
    </div>
  );
  });
  setActive(_param);
  setlistContent(content);
});
}

function prepareModal(e){
  //console.log(e.currentTarget.getAttribute('data-image'));
  var obj = e.currentTarget;
  var image = obj.getAttribute('data-image');
  var title = obj.getAttribute('data-title');
  var desc = obj.getAttribute('data-desc');
  var date = obj.getAttribute('data-date');

  openModal(image,title,desc,date);

}
function openModal(image,title,desc,date){

  setModal('on');
  setModalData({image: image, title: title,desc: desc, date: date });
}

function createMarkup(str) {
    return {
       __html: str    };
 };


 function toggleNave(){
   //navStatus   ? navOn(): navOff();
   //console.log(navStatus);
   navStatus ? setNavStatus(false) : setNavStatus(true);
   gsap.to(document.body,1,{delay: .2, ease: 'Quad.easeOut', scrollTo:{y: _y, autoKill: false }});

 }


if( isMobile && isLoaded ){
  //console.log(props);
  var image = props.data.photos.edges[0];

return (

  <Layout>

        <div className="main-projects" ref={scrollElem}>
         <GatsbyImage
          fluid={image.node.childImageSharp.fluid}
           alt={image.node.base.split("-").join(" ").split(".")[0]}
          image={getImage(image.node)}
        />
        </div>
        <div id="listSelector">
        <a className={'c-' + active} href="#cine"
        onClick={()=> {loadProjects('c');
        gsap.to(document.body,1,{delay: 0, ease: 'Quad.easeOut', scrollTo:{y: 540, autoKill: false }});
        } }>CINE</a>
        <a className={'t-' + active}href="#television" onClick={()=> {loadProjects('t');
        gsap.to(document.body,1,{delay: 0, ease: 'Quad.easeOut', scrollTo:{y: 540, autoKill: false }});
        } }>TELEVISION</a>
        </div>
        <div className="projectList">
          {listContent}
          <div className="bottomSpacer">
          </div>
          </div>


  {/* <DragScrollViewMobile mobile={true} width={width} height={height} location={props.location} /> */}
  <nav role="navigation">
  <div id="menuToggle">
  <input type="checkbox"
  onClick={()=>toggleNave() }
  />

  <span></span>
  <span></span>
  <span></span>

  <ul id="menuNav" className="home">
  <li><a className="homeLink" href="/"><Trans>HOME</Trans></a></li>

  <li className="menuNavItem">
  <a href={"/" + props.pageContext.language + "/bio"}>BIO</a>
  </li>
  <li className="menuNavItem">
  <a className="activePage" href={"/" + props.pageContext.language + "/" +t('l_projects')}><Trans>PROYECTOS</Trans></a>
  </li>
  <li className="menuNavItem">
  <a href={"/" + props.pageContext.language + "/" + t('l_contact')}><Trans>CONTACTO</Trans></a>
  </li>
  <li className="menuNavItem small">
  <a className={'lang_en_'+ props.pageContext.language} href="/en">EN</a>  <a className={'lang_es_'+ props.pageContext.language} href="/es">ES</a>
  </li>

  </ul>
  </div>
  </nav>

  <div id="nav_bottom">
  <div id="nav_bottom_inner">
  <div id="logo"><a href="/">OSCAR JAENADA</a></div>

  </div>
  </div>

  <div id="modalImage" className={modal}>
  <div id="modalImageInner">
  <a className="close" onClick={() => {setModal('off'); setModalData({})} }>
  <span/>
  <span/>
  </a>
  <img src={modalData.image} alt=""/>
  <h3>{modalData.title}</h3>
  <div dangerouslySetInnerHTML={createMarkup(modalData.date + '<br/>' + modalData.desc  )} />
  </div>
  </div>

  </Layout>

);
}else if( !isMobile && isLoaded){

  var image = props.data.photos.edges[1];
  return (

    <Layout>
    <GatsbyImage
     fluid={image.node.childImageSharp.fluid}
      alt={image.node.base.split("-").join(" ").split(".")[0]}
     image={getImage(image.node)}
     height={900}
   />
   <div id="listSelector">
   <a className={'c-' + active} href="#cine" onClick={()=> loadProjects('c') }>CINE</a>
   <a href="#television" onClick={()=> loadProjects('t') }>TELEVISION</a>
   </div>
   <div className="projectList">
     {listContent}
     <div className="bottomSpacer">
     </div>
     </div>

     <nav role="navigation">
     <div id="menuToggle">
     <input type="checkbox"
     onClick={()=>toggleNave() }
     />

     <span></span>
     <span></span>
     <span></span>

     <ul id="menuNav" className="home">
     <li><a className="homeLink" href="/"><Trans>HOME</Trans></a></li>

     <li className="menuNavItem">
     <a href={"/" + props.pageContext.language + "/bio"}>BIO</a>
     </li>
     <li className="menuNavItem">
     <a className="activePage" href={"/" + props.pageContext.language + "/" +t('l_projects')}><Trans>PROYECTOS</Trans></a>
     </li>
     <li className="menuNavItem">
     <a href={"/" + props.pageContext.language + "/" + t('l_contact')}><Trans>CONTACTO</Trans></a>
     </li>
     <li className="menuNavItem small">
     <a className={'lang_en_'+ props.pageContext.language} href="/en">EN</a>  <a className={'lang_es_'+ props.pageContext.language} href="/es">ES</a>
     </li>

     </ul>
     </div>
     </nav>

     <div id="nav_bottom">
     <div id="nav_bottom_inner">
     <div id="logo"><a href="/">OSCAR JAENADA</a></div>

     </div>
     </div>

     <div id="modalImage" className={modal}>
     <div id="modalImageInner">
     <a className="close" onClick={() => {setModal('off'); setModalData({})} }>
     <span/>
     <span/>
     </a>
     <img src={modalData.image} alt=""/>
     <h3>{modalData.title}</h3>
     <div dangerouslySetInnerHTML={createMarkup(modalData.date + '<br/>' + modalData.desc  )} />
     </div>
     </div>

    </Layout>

  );
}
}


export default ProjectsPage

export const Head = ({ location, params, data, pageContext }) => {
  //console.log(data.locales.edges[0].node.data);
  var json_head = JSON.parse(data.locales.edges[0].node.data);
return(
  <>
    <title>{json_head.title_proj}</title>
    <meta
      name="twitter:url"
      content={`https://oscarjaenada${location.pathname}`}
    />
    <meta name="HandheldFriendly" content="true"/>
    <meta name="description" content={json_head.desc_proj} />
    <meta property="og:title" content="Oscar Jaenada" />
    <meta name="author" content="Website by Rémi Blot - remiblot.com"/>
    <meta property="og:type" content="website" />
    <meta property="og:url" content={`https://oscarjaenada.com${location.pathname}`} />
    <meta property="og:image" content="https://oscarjaenada.com/static/oscar-jaenada.jpg" />
    <meta property="og:description" content={json_head.desc_proj} />
    <meta property="og:site_name" content={json_head.title_proj} />
    <meta property="fb:app_id" content="" />
    <link rel="canonical" href="https://oscarjaenada.com/en/projects" />
  </>
);};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["common", "index"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    file(relativePath: { eq: "index/OJ_DSC5488-1.jpg" }) {
      id
      root
      relativePath
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: DOMINANT_COLOR
          width: 400
        )
      }
    }
    photos: allFile(
          sort: { fields: base, order: ASC }
          filter: {relativeDirectory: {eq: "projects"}}
        ) {
          edges {
            node {
              id
              base
              childImageSharp {
                gatsbyImageData(
                  placeholder: DOMINANT_COLOR
                  height: 1200
                  quality: 80
                  formats: AUTO
                  transformOptions: {fit: COVER, cropFocus: ATTENTION}

                )
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }


  }
`;
